// em
const mqBreakpoints = {
  '--mobile-down': '(max-width: 25.999em)',
  '--mobile-up': '(min-width: 26em)',
  '--phablet-down': '(min-width: 32.499em)',
  '--phablet-up': '(min-width: 32.5em)',
  '--tablet-down': '(max-width: 39.999em)',
  '--tablet-up': '(min-width: 40em)',
  '--tablet-lg-down': '(min-width: 63.999em)',
  '--tablet-lg-up': '(min-width: 64em)',
  '--laptop-down': '(max-width: 79.999em)',
  '--laptop-up': '(min-width: 80em)',
  '--desktop-down': '(max-width: 99.999em)',
  '--desktop-up': '(min-width: 100em)',
  '--ultrawide': '(min-width: 125em)',
};

// px
const pxBreakpoints = {
  '--mobile-up': 416,
  '--phablet-up': 520,
  '--tablet-up': 640,
  '--tablet-lg-up': 1024,
  '--laptop-up': 1152,
  '--desktop-up': 1600,
  '--ultrawide': 2000,
};

export { mqBreakpoints, pxBreakpoints };
