import lazySizes from 'lazysizes';
import { listen } from 'quicklink';
import { mqBreakpoints } from './breakpoints';

// Lazysizes
lazySizes.cfg.lazyClass = 'lazyload';
lazySizes.cfg.customMedia = {
  ...mqBreakpoints,
};

// Quicklink
listen({
  ignores: [
    /\/logout/,
    (uri) => uri.includes('#'),
    (uri) => uri.includes('/account'),
    (uri) => uri.includes('/cart'),
    (uri) => uri.includes('.zip'),
    (_, elem) => elem.hasAttribute('noprefetch'),
  ],
});
