import Alpine from 'alpinejs';
import { throttle } from 'lodash-es';

import '../utils/lazyloading';

// Header scrolled class
document.addEventListener(
  'scroll',
  throttle(() => {
    const header = document.querySelector('.header');
    header.classList.toggle('scrolled', window.scrollY > 0);
  }, 250)
);

// Bouncy element animations
document.addEventListener('DOMContentLoaded', () => {
  const items = document.querySelectorAll('.bouncy');

  const observer = new IntersectionObserver(
    (entries, obs) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animation-loaded');
          obs.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.25 }
  );

  items.forEach((item) => {
    observer.observe(item);
  });
});

// Contact form
Alpine.data('contact', () => ({
  isOpen: false,
  isSubmitted: false,
  showSuccessPopup: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  platform: '',
  system: '',
  errorMessage: '',
  emptyFields: {
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  },

  init() {
    if (globalThis.location.href.includes('contact_posted=true')) {
      this.isSubmitted = true;
      this.showSuccessPopup = true;
    }
  },

  submit($event) {
    const $form = document.querySelector('.contact__inner-container-form');

    this.errorMessage = '';

    Object.keys(this.emptyFields).forEach((key) => {
      this.emptyFields[key] = !this[key];
    });

    if (!this.isFormComplete()) {
      this.errorMessage = 'Please complete the required fields.';
      $event.preventDefault();
      return;
    }

    $form.submit();
  },

  isFormComplete() {
    const hasEmptyFields = Object.values(this.emptyFields).some(
      (value) => value
    );
    return !hasEmptyFields;
  },
}));

// Case study sliders
Alpine.data('article', () => ({
  hideArrows: {},
  disablePrev: {},
  disableNext: {},

  init() {
    const $sliders = document.querySelectorAll('.slider');

    $sliders.forEach(($slider) => {
      const { idx } = $slider.dataset;

      this.hideArrows[idx] = $slider.clientWidth === $slider.scrollWidth;
      this.disablePrev[idx] = true;

      $slider.addEventListener(
        'scroll',
        throttle((e) => {
          this.disablePrev[idx] = e.target.scrollLeft < 1;
          this.disableNext[idx] =
            e.target.scrollLeft + e.target.clientWidth >=
            e.target.scrollWidth - 20;
        }, 75)
      );

      window.addEventListener(
        'resize',
        throttle(() => {
          this.hideArrows[idx] = $slider.clientWidth === $slider.scrollWidth;
        }, 200)
      );
    });
  },

  slide(idx = 1, dirRight = true, gap = 20) {
    const $slider = document.querySelector(`.slider[data-idx="${idx}"]`);
    const width = $slider.lastElementChild.clientWidth + gap;
    $slider.scrollBy(dirRight ? width : width * -1, 0);
  },
}));

globalThis.Alpine = Alpine;

Alpine.start();

// document.addEventListener('click', (event) => {
//   if (event.target.matches('a')) {
//     event.preventDefault();

//     const url = event.target.href;

//     document.startViewTransition(() =>
//       fetch(url)
//         .then((response) => response.text())
//         .then((html) => {
//           document.body.innerHTML = html;
//           Alpine.start();
//         })
//     );
//   }
// });
